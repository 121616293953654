import React from 'react';

const BannerCardImage = () => {
  return (
    <div
      className="shadow-lg rounded-lg relative w-100"
      style={{ height: '450px', overflow: 'hidden' }}
    >
      {/* Background Image */}
      <img
        src="https://dashboard.ownfood.com.bd/static/media/ownfood-banner-2.4cf6de9730d81b27f347.png" // Replace with your image URL
        alt="Promotional Banner"
        className="w-100 h-100"
        style={{ objectFit: 'cover' }} // Ensures the image covers the entire container without distortion
      />
    </div>
  );
};

export default BannerCardImage;
