import React, { useEffect } from 'react';
import SliderProvider from '../../Common/Slider/SliderProvider';
import { GetNearestSellerAPI } from '../../../API/SellerAPI';
import { useDispatch, useSelector } from 'react-redux';
import SellerCard from '../../Common/Cards/SellerCard/SellerCard';

const NearestSeller = ({ coordinates }) => {
  const dispatch = useDispatch();

  // Fetch nearest sellers when the component mounts or when `coordinates` change
  useEffect(() => {
    const fetchSellers = async () => {
      if (!coordinates?.lat || !coordinates?.lon) {
        console.error("Coordinates are missing or invalid");
        return;
      }

      const success = await GetNearestSellerAPI(coordinates);
      if (!success) {
        console.error("Failed to fetch nearest sellers.");
      }
    };

    fetchSellers();
  }, [coordinates]);

  // Selector to get the nearest seller data from the Redux store
  const sellerData = useSelector((state) => state.seller.getNearestSellerData);
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 4,
  };
  return (
    <div>
      <h2>Nearest Sellers</h2>

      {sellerData && sellerData.length > 0 ? (
        <SliderProvider
          key="slider"
          sliderSettings={settings}
          food={sellerData}
        cardComponent={SellerCard} 
        />
      ) : (
        <p>No sellers found nearby.</p>
      )}
    </div>
  );
};

export default NearestSeller;
