import React from 'react'
import Header from '../Common/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Common/Footer'
import useIsMobile from '../../customHooks/useIsMobile'
import BottomBar from '../../MobileLayout/BottomBar'

const HomeLayout = () => {
  const isMobile = useIsMobile();
  return (
    <div>
      <div style={{ display: "block", '@media print': { display: "none" } }}>
        <Header />

      </div>
      <div>
        <Outlet />
      </div>
      <div style={{ display: "block", '@media print': { display: "none" } }}>
        {!isMobile && <Footer />}
        {
          isMobile && <BottomBar />
        }
      </div>
    </div>
  )
}

export default HomeLayout